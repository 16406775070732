import { TiTick } from "react-icons/ti";
//import Donut from "./donut"
export default function Experiance(){
 
  return<section id="experiance" className="defaultTop flex flex-col px-5 py-10 md:flex-row">
<div className="md:w-full text-black">
<h1 className="text-center text-4xl py-5 font-my-des-font"><span className="text-white">My </span>Experiance</h1>
<div className="flex px-5">
<p className="pt-2 py-5 text-xl  text-black"><span className="text-white">SENIOR WEB DEVELOPER - ISTUDIO TECHNOLOGIES </span>  MAR 2019 - PRESENT (5 + YEARS)  CHENNAI</p>
</div>

<div className="flex px-5">
 <p className="pt-2 py-5 text-xl  text-black"><span className="text-white">WEB DEVELOPER - BRAVE TECHNOLOGIES </span>  FEB 2017 - FEB 2019 (2 YEARS)  CHENNAI</p>
</div>

<div className="flex px-5">
<p className="pt-2 py-5 text-xl text-black"><span className="text-white">WEB DEVELOPER - ENA GAME STUDIO </span>  FEB 2016 - JUNE 2016 (5 MONTHS)  CHENNAI</p>
</div>

<div className="flex px-5">
 <p className="pt-2 py-5 text-xl text-black"><span className="text-white">ASSISTANT PROFESSOR CUM WEB DEVELOPER - SJCET</span> MAR 2015 - JAN 2016 (10 MONTHS)   THANJAVUR</p>
</div>
<div className="flex px-5">
 <p className="pt-2 text-left text-xl  text-black"><span className="text-white">WEB DEVELOPER- AKAS MEDICAL</span>  DEC 2013 – FEB 2015 (1 YEAR AND 2 MONTHS) CHENNAI</p>
</div>


</div>


  </section>

}