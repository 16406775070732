
import { TiTick } from "react-icons/ti";
export default function Education(){
  return<section id="education" className="bg-header flex flex-col px-5 py-10 md:flex-row">
  <div className="md:w-full text-black">
  <h1 className="text-center text-4xl py-5 font-my-des-font"><span className="text-white">My </span>Education</h1>
  <div className="flex">
 <p className="pt-2 text-left text-xl py-5 px-5 text-black"><span className="text-white">M.Tech (C.S.E.) - PRIST UNIVERSITY</span>  2012 - 7.64 CGPA .</p>
  </div>
  
  <div className="flex ">
  <p className="pt-2 text-left text-xl py-5  px-5 text-black"><span className="text-white">BE (C.S.E.) - ARASU ENGINEERING COLLEGE ANNA UNIVERSITY </span>  2010 - 64 Percentage </p>
  </div>
  
  <div className="flex">
  <p className="pt-2 text-left text-xl py-5  px-5 text-black"><span className="text-white">DIPLOMA (I.T.) - CCMRP DOTE </span> 2006 - 76 Percentage</p>
  </div>

  </div>
  
  
    </section>;
  

}