import resume from "../assets/YUVARAJ_SELVAM_RESUME.pdf";
import resumeimg from "../assets/resume.png";

export default function Resume(){


return <section id="resume" className="flex flex-col md:flex-row px-5 bg-header py-10">
<div className="md:w-1/2  flex justify-center">
<img className="h-[300px]" src={resumeimg}></img>
</div>
<div className="md:w-1/2  flex justify-center">
<div className="flex flex-col justify-center">
<h2 className="text-4xl font-my-des-font text-center" ><span className="text-white">My</span> Resume </h2>
<div className="py-5">
<a href ={resume}  target="_blank"  className="text-white bg-gradient-to-r from-teal-200 via-teal-400 to-teal-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Download My Resume</a>

</div>
</div>


</div>


</section>;



};