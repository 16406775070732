import { Bars3Icon } from '@heroicons/react/24/solid'
import { useState } from 'react'
export default function Header(){
const [togMenu,SetTogMenu] =  useState(false);
  return <header className="flex justify-between px-5 py-4 bg-header">
    <a className ="font-bold text-black" href=""><span className='text-white'>Yuvaraj</span> Selvam</a>
    <nav className="hidden md:block">
    <ul className="flex text-white">
    <li><a href="#about">About</a></li>
      <li><a href="#experiance">Experiance</a></li>
      <li><a href="#education">Educational</a></li>
      <li><a href="#projects">Projects</a></li>
      <li><a href="#resume">Resume</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
    </nav>
    {togMenu && <nav className="block md:hidden">
    <ul onClick={()=>SetTogMenu(!togMenu)} className="mobilenav  flex flex-col text-white">
      <li><a href="#about">About</a></li>
      <li><a href="#experiance">Experiance</a></li>
      <li><a href="#education">Educational</a></li>
      <li><a href="#projects">Projects</a></li>
      <li><a href="#resume">Resume</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
    </nav>}
    <button onClick={()=>SetTogMenu(!togMenu)} className='block md:hidden'><Bars3Icon className='text-white h-5'></Bars3Icon> </button>
  
  </header>
}