import developer from "../assets/developer_1.png";
import { AiOutlineTwitter ,AiOutlineFacebook,AiOutlineLinkedin} from "react-icons/ai";
export default function DefaultTop(){

  const data_one ={
    sub_title : "I am a Full stack Developer"
  };

return <section className="defaultTop flex flex-col md:flex-row px-5 py-10 justify-center">
  <div className="md:w-1/2  flex flex-col justify-center">
  <h1 className=" text-white text-5xl font-my-des-font">HI, <br/> I am <span className="text-black">YUVARAJ</span> SELVAM
  <p className="text-3xl">{data_one.sub_title}</p>
  </h1>
  <div className="text-white flex py-10">
   {/*  <a className="pr-5  hover:text-black" href=""><AiOutlineTwitter size={40}/></a> */}
    <a className="pr-5  hover:text-black" href="https://en-gb.facebook.com/s.s.yuvi"><AiOutlineFacebook  size={40}/></a>
    <a className="pr-5  hover:text-black" href="https://www.linkedin.com/in/yuvaraj-selvam-1a33ba19"><AiOutlineLinkedin  size={40}/></a>
 
  </div>
  </div>
  
  <img className="md:w-1/3" src={developer}></img>
  </section>;

}