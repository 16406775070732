import developer from "../assets/developer_2.png";

export default function AboutUs(){
  const about ={
    description : "Self-motivated team player with ten plus years of experience seeking a position as a"+ 
"lead web developer with Next Generation Web Development where I can apply my advanced "+
"knowledge of web design with my leadership abilities to meet client needs and exceed their "+
"expectations"
  };
return <section id="about" className="flex flex-col md:flex-row px-5 bg-header py-10">
    <div className="md:w-1/2  ">
   <img className="" src={developer}></img>
   </div>
  <div className="md:w-1/2  flex justify-center">
  <div className="flex flex-col justify-center">
  <h2 className="text-4xl font-my-des-font text-center" ><span className="text-white">About</span> Me </h2>
  <p className="py-5 text-xl text-white">{about.description}</p>
  </div>
 

  </div>
  
 
  </section>;

}