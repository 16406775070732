

export default function Footer(){


return <div className="bg-header flex flex-col items-center text-white">

<p className="py-4 text-center "><span className="font-bold">&copy;</span> yuvarajselvam.in</p>



</div>;




};