

export default function Contact(){
  const contact ={
    email : "yuvan.webdeveloper@gmail.com",
    mobile : "(+91) 9578884885",
  };

return <section id="contact" className="flex flex-col px-5 defaultTop py-10">

<div className="flex flex-col items-center text-white">

<h2 className="text-4xl py-5 font-my-des-font text-center" >Contact <span className="text-black">Me</span> </h2>

<p>If you want to discuss more in details please contact me</p>
<p className="py-2"><span className="font-bold">E-Mail Id :</span> {contact.email}</p>
{/* <p  className="py-2"><span className="font-bold">Mobile Number :</span> {contact.mobile} </p> */}


</div>


</section>;



};