
import './App.css';
import Header from './components/header';
import DefaultTop from './components/defaultTop';
import AboutUs from './components/about';
import Experiance from './components/experiance';
import Projects from './components/projects';
import Education from './components/education';
import Resume from './components/resume';
import Contact from './components/contact';
import Footer from './components/footer';
function App() {
  return (
    <div className="App">
      <Header></Header>
      <DefaultTop></DefaultTop>
      <AboutUs></AboutUs>
      <Experiance></Experiance>
      <Education></Education>
      <Projects></Projects>
      <Resume></Resume>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;
